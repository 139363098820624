.App {
  text-align: center;
  height: 100%;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 24px;
}

.hero {
  width: 100%;
  height: fit-content;
  align-self: center;
}

.hero-text {
  background-color: rgb(227, 229, 230);
  padding-bottom: 5px;
}

.header1 {
  font-size: 45px;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
}

.header2 {
  font-size: 32px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 5px;
}

.header3 {
  font-size: 24px;
  padding-left: 10px;
  padding-right: 10px;
}

.hero-image-desktop {
  width: 100%;
  margin-bottom: -8px;
}

.hero-image-mobile {
  visibility: hidden;
  display: none;
}

.menu-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 20px;
}

.menu-item {
  text-decoration: none;
  color: black;
  padding: 10px;
}

.menu-item:hover {
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  cursor: pointer;
}

.menu-item-home {
  text-decoration: none;
  color: black;
  padding: 10px;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.trails-body {
  height: 100%;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 100px 50px 100px;
  align-self: center;
  background-color: rgb(227, 229, 230);
  text-align: left;
}

.trails-wrapper {
  display: flex;
  flex-direction: column;
}

form {
  background-color: rgb(115, 165, 189);
  font-weight: bold;
  border-style: solid;
  border-radius: 5px;
  width: 100%;
  max-width: 500px;
}

.form-button {
  background-color: transparent;
  border: none;
  width: 100%;
  height: 54.5px;
  font-size: 32px;
  font-weight: bold;
}

.location-wrapper {
  display: flex;
  flex-direction: column;
}

.map {
  width: 100%;
  max-width: 500px; 
  height: 450 px; 
  border: 0; 
}

.map-wrapper {
  display: flex;
  flex-direction: column;
}

.trails-map {
  width: 100%;
  max-width: 500px;
}

.updates-wrapper {
  display: flex;
  flex-direction: column;
}

.fb-page {
  height: 600px;
  width: 100%;
  max-width: 500px;
}

.contacts-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.contact-wrapper {
  display: flex;
  flex-direction: row;
  line-height: 40px;
}

.contact-logo {
  height: 25px;
  width: 25px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  padding-right: 10px;
}

.contact-logo-2 {
  line-height: 40px;
  padding-right: 13px;
  padding-left: 5px;
}

.contact-text {
  line-height: 40px;
  color: black;
  text-decoration: none;
}

.contact-text:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .hero-image-desktop {
    visibility: hidden;
    display: none;
  }
  
  .hero-image-mobile {
    width: 100%;
    margin-bottom: -8px;
    visibility: visible;
    display: block;
  }

  .trails-body {
    padding: 50px;
  }

  .menu-item {
    padding: 8px;
  }

  .fb-embed-large {
    visibility: hidden;
    display: none;
  }
  
  .fb-embed-medium {
    visibility: visible;
    display: block;
    height: 600px;
    width: 400px;
  }

  .fb-embed-small {
    visibility: hidden;
    display: none;
  }
}

@media only screen and (max-width: 499px) {
  .trails-body {
    padding: 20px;
  }

  .fb-embed-large {
    visibility: hidden;
    display: none;
  }
  
  .fb-embed-medium {
    visibility: hidden;
    display: none;
  }

  .fb-embed-small {
    visibility: visible;
    display: block;
    height: 600px;
    max-width: 330px;
    margin-left: auto;
    margin-right: auto;
  }
}